import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown';
import BlogImage from '../Reusables/BlogImage'
import BlogBlock from "../Home/BlogBlock";

const StyledWrapper = styled.div`
.date{
    font-size:24px;
lineheight:32px;
    padding-top:10px;
    padding-bottom:17px;
}

.author{
    font-size:17px;
    line-height:27px;
    padding-bottom:17px;
}

.author a{
    text-decoration:underline;
    color: black;
}

.author img{
    width:100%;
}

.title-section{
    max-width:1430px;
}

.media-query{
    @media(max-width:600px){
        line-height:25px;
    }
}

.margin-top{
    margin-top:70px;
}
.blog-content{
    margin-top: -105px;
    margin-left: 70px;
}
.blog-content-two{
    margin-top: -75px;
    @media(max-width:600px){
    margin-top: -35px;
    }
}
.blog-Image {
    margin-left: 57px;
    margin-top: -32px;
    @media(max-width:600px){
    margin-top: -32px;
    margin-top: -38px;
    }
}
.main-content{
    @media(max-width:600px){
    margin-top: 105px;
    margin-left: -65px;
    margin-bottom: -80px;
    }
}


`

export default function BlogView({data}){
    const parts = data.description.split('![');


  // Extract the text part (before the image) and the image part
  const textPart = 
  {
    description: parts[0]
  };
// const textPart =  parts[0]
 
  const imagePart =  parts[1] && parts[1].split('![');

  // Split the image part to separate the URL and possible text after the image
  const imageInfo = imagePart && imagePart[0].split(')');
  const imageUrl = imageInfo && '/' + imageInfo[0].split('/').slice(3).join('/');
  const textAfterImage = 
  {
    description: imageInfo && imageInfo[1] || ''
  };

    return(
        <StyledWrapper>
            <div className="container-fluid padding-left-8 padding-right-8 margin-top">
                <div className="row">
                    <div className="col-10 col-md-12 col-sm-12 title-section px-0">
                    <h1 className="media-query">{data.title}</h1>
                    </div>
                    <div className="date px-0 col-12">
                        {data.date}
                    </div>
                    <div className="author col-12 px-0">{data.author}</div>
                </div>
            </div>
            
                <div className="main-content">
                    <div className="author col-12 px-0">
                    <div className='blog-content'>
                    <BlogBlock summary={textPart} />
                    </div>
                    {/* <ReactMarkdown source={textPart} /> */}
                    <div className='blog-Image'>
                    <div className="container-fluid  padding-right-8 margin-top">
                        {imageUrl && (
                        <div className={
                            data.ImageSize === "Small"
                            ? "col-lg-4"
                            : data.ImageSize === "Large"
                            ? "col-lg-8" :data.ImageSize === 'ExtraLarge' ?
                            "col-lg-12"
                            : "col-lg-6"
                          }>
                            <BlogImage heroImage={imageUrl} />
                        </div>
                        )}
                        <div className='blog-content-two'>
                        <BlogBlock summary={textAfterImage} />
                        </div>
                        </div>
                        </div>
                        {/* <div className='blog-content-two'>
                        <BlogBlock summary={textAfterImage} />
                        </div> */}
                        {/* <ReactMarkdown source={textAfterImage} /> */}
                    </div>
                </div>
        </StyledWrapper>
    );
}