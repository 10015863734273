import React from "react";
import Layout from "../layout";
import styled from "styled-components";
import BlogView from "./BlogView";
import HeroImages from "../Reusables/HeroImages";
import { graphql } from "gatsby";
import axios from "axios";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../Reusables/Loader";
const StyledWrapper = styled.div``;
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Blog />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/blogs`);
};
function Blog({ data }) {
  const [newData, setNewData] = useState(null);
  const [currentUrl, setCurrentUrl] = useState(null);
  const { data: pageData } = useQuery("mainData", fetchData);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
  }, [pageData]);
  useEffect(() => {
    setCurrentUrl(window.location.pathname.split("/news/")[1]);

    if (currentUrl !== null) {
      setCurrentUrl(currentUrl.replace("%20", " "));
    }
  }, [currentUrl]);

  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData &&
          currentUrl &&
          newData.length > 0 &&
          newData.map(
            item =>
              item.handle === currentUrl && (
                <div>
                  {item.Image !== null && (
                    <HeroImages heroImage={item.Image.url} />
                  )}
                  <BlogView data={item} />
                </div>
              )
          )}
      </StyledWrapper>
    </Layout>
  );
}
